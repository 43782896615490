import { CloneUtil } from "../../base/helper/clone-util";
import { CryptoHelper } from "../../base/helper/crypto-helper";
import { JobTemplate } from "./job-template";
import { MeasuredPoint } from "./measured-point";
import { Photo } from "./photo";
import { Property } from "./property";

/**
 * Job is a DTO to hold all values to describe a job.
 */
export class Job extends JobTemplate {
    public static jobPropertyTemplate: string = "Job.jobTemplate";

    public measuredPoints: Array<MeasuredPoint> = [];
    public photos: Array<Photo> = [];
    public jobTemplateId: number|undefined;

    public static from(jobTemplate: JobTemplate): Job {
        const job: Job = {
            ...CloneUtil.clone(jobTemplate),
            id: undefined,
            correlationId: CryptoHelper.getUUID(),
            version: 0,
            jobTemplateId: jobTemplate.id,
            measuredPoints: this.initializeMeasuredPoints(jobTemplate),
            photos: []
        };
        job.properties.push({
            nameKey: "Job.jobTemplate",
            nameEditable: false,
            value: this.getJobTemplateDisplayName(jobTemplate),
            deletable: false,
            deleted: false,
            isNew: false
        });

        job.properties = job.properties.filter((p: Property) => {
            const remove: boolean = [JobTemplate.jobTemplatePropertyName].includes(`${p.nameKey}`);
            return !remove;
        });
        return job;
    }

    private static initializeMeasuredPoints(jobTemplate: JobTemplate): Array<MeasuredPoint> {
        const measuredPoints: Array<MeasuredPoint> = [];
        for (const part of jobTemplate.parts) {
            if (part.image) {
                for (const measurementPoint of part.image?.measurementPoints) {
                    measuredPoints.push({
                        measurements: [],
                        partId: part.id!,
                        measurementPointId: measurementPoint.id!
                    });
                }
            }
        }
        return measuredPoints;
    }

    public static getJobDisplayName(job: Job): string|undefined {
        const parts: Array<string> = [];
        parts.push(`${job.id}`);

        const displayProperties: Array<Property> = (job.properties ?? []).filter((property: Property) => property.nameKey != Job.jobPropertyTemplate);
        for (let i: number = 0; i < Math.min(2, displayProperties.length); i++) {
            if (displayProperties[i].value) {
                parts.push(displayProperties[i].value!);
            }
        }
        const template: Property|undefined = Property.findByNameKey(Job.jobPropertyTemplate, job.properties);
        const result: string = template ? `${parts.join(" - ")} (${template.value})` : parts.join(" - ");

        return result;
    }
}

